import React, { useState } from "react";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Util from "../utilities/Util";
import Tags from "../features/Tags";

import { useInView } from "react-intersection-observer";

const LibraryListItem = ({ item, onSelectItem, onFilterChanged }) => {
  const [cardHover, setCardHover] = useState(undefined);

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  const onSelectFilter = (e, type, value) => {
    onFilterChanged("toggle", type, value);
    e.stopPropagation();
  };

  let firstContent = {};
  if (
    item &&
    item.content &&
    item.content.content &&
    item.content.content.length
  ) {
    firstContent = item.content.content[0];
  }

  let video;
  if (cardHover === item.id && firstContent.videoURL) {
    video = (
      <video
        key={firstContent.videoURL}
        className="video"
        autoPlay
        muted
        loop
        playsInline
      >
        {Util.isIOS() === false && (
          <source
            src={Util.addVideoUrlSuffix(firstContent.videoURL, "small")}
            type="video/webm"
          />
        )}
        {Util.isIOS() === false && (
          <source src={firstContent.videoURL} type="video/webm" />
        )}
        <source
          src={Util.addVideoUrlSuffix(firstContent.videoURL, "small", "mp4")}
          type="video/mp4"
        />
        <source
          src={Util.addVideoUrlSuffix(firstContent.videoURL, "", "mp4")}
          type="video/mp4"
        />
      </video>
    );
  }

  let marker;
  if (item.marker) {
    marker = <img className="marker" src={item.marker} alt="marker" />;
  }

  let image;
  let thumbnail = Util.addImageFitIn(firstContent.imageURL, 720, 360);
  if (thumbnail) {
    image = (
      <img className="image" src={thumbnail} alt="thumbnail" loading="lazy" />
    );
  }

  return (
    <div
      ref={ref}
      key={item.id}
      className="item"
      onClick={() => onSelectItem(item)}
    >
      {!inView && (
        <>
          <div className="item-content"></div>
          <div className="metadata"></div>
        </>
      )}
      {inView && (
        <>
          <div className="item-content">
            <div
              className="package"
              onMouseEnter={() => setCardHover(item.id)}
              onMouseLeave={() => setCardHover()}
            >
              {marker}
              {image}
              {video}
            </div>
          </div>
          <div className="metadata">
            <div className="name-group" style={{ marginBottom: "15px" }}>
              <div className="name">{Util.replacePipeSymbolInString(item.name)}</div>
              {Util.getLibraryNewReleaseDaysLeft(item) !== 0 && (
                <div className="new">NEW</div>
              )}
            </div>
            <Tags
              item={item}
              filter="tag_layout"
              onSelectFilter={onSelectFilter}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LibraryListItem;
