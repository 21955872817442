import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";
import SidebarFolders from "./SidebarFolders";
import FetchDataFunctions from "../utilities/FetchDataFunctions";

import {
  IconNewOverlay,
  IconHome,
  IconLibrary,
  IconMyOverlays,
  IconAll,
  IconFavorites,
  IconTrash,
  IconLogin,
  IconLogout,
  IconCollapse,
  IconImage,
  IconUploadImage,
  IconAngleUp,
  IconAngleDown,
  IconCreditCard,
  IconInbox,
} from "../utilities/SvgIcon";

import mixpanel from "../utilities/MixPanel";
import moengage from "../utilities/MoEngage";

const Sidebar = ({ isAuthenticated, inboxFolderHasContent, onShowDialog }) => {
  const [ContextState, ContextDispatch] = useContext(Context);
  const [playUnoVideo, setPlayUnoVideo] = useState(false);

  const hasInbox = Util.getHasInbox(ContextState.userInfo) && inboxFolderHasContent;

  const videoFiles = [
    "/images/zipFileKicked.webm",
    "/images/zipFileBurned.webm",
  ];

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If we are already playing the uno video, we don't need to start the timer
    if (playUnoVideo) {
      return;
    }

    let timer;

    const startTimer = () => {
      const randomTime = Math.random() * 300 + 300; // Random time between 300 and 600 seconds
      timer = setTimeout(() => {
        setPlayUnoVideo(true);
      }, randomTime * 1000);
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearTimeout(timer);
        setPlayUnoVideo(false);
      } else {
        startTimer();
      }
    };

    startTimer();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup function to clear the timeout when the component unmounts
    return () => {
      clearTimeout(timer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [playUnoVideo]); // Effect runs every time playUnoVideo changes

  useEffect(() => {
    if (ContextState.dashboardCreateFolder) {
      // make sure that we see the large menu when we create a folder
      ContextDispatch({
        type: "SET_MENU_SIZE",
        payload: "large",
      });

      // make sure that the menu is open when we create a folder
      if (ContextState.dashboardCreateFolder === "images") {
        ContextDispatch({
          type: "SET_MENU_IMAGE_SHOW",
          payload: true,
        });
      } else if (ContextState.dashboardCreateFolder === "controlapps") {
        ContextDispatch({
          type: "SET_MENU_OVERLAY_SHOW",
          payload: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.dashboardCreateFolder]);

  // add a selected class to the menu based on the react router location
  const classForLocation = (c, l) => {
    if (String(location.pathname).indexOf(l) === 0) {
      return c + " selected";
    } else {
      return c;
    }
  };

  const getRandomVideo = () => {
    return videoFiles[Math.floor(Math.random() * videoFiles.length)];
  };

  const renderEntry = (icon, name) => {
    return (
      <>
        {icon}
        {ContextState.menuSize === "large" && (
          <span className="title">{name}</span>
        )}
      </>
    );
  };

  const renderNewItem = () => {
    // if we are in one of the myimages pages, we show the upload image button
    if (String(location.pathname).indexOf("/myimages") === 0) {
      return (
        <div
          className="new-overlay"
          onClick={() => onShowDialog("uploadimage")}
        >
          <IconUploadImage fill="#ffffff" />
          {ContextState.menuSize === "large" && <span>Upload image</span>}
        </div>
      );
    } else {
      return (
        <div
          className="new-overlay"
          onClick={() => {
            navigate("/library");
          }}
        >
          <IconNewOverlay fill="#ffffff" />
          {ContextState.menuSize === "large" && <span>New Overlay</span>}
        </div>
      );
    }
  };

  if (ContextState.authenticationStatus === "busy") {
    return (
      <div
        className={
          "sidebar" + (ContextState.menuSize === "large" ? "" : " small")
        }
      >
        <img
          className="logo"
          src={
            ContextState.menuSize === "large"
              ? Config.unoLogoMedium
              : Config.unoLogoTiny
          }
          onClick={() => navigate("/home")}
          alt="uno logo"
        />
      </div>
    );
  }

  const onFolderClick = (folder, type, nav) => {
    // we clicked on the current folder
    if (String(location.pathname).indexOf(nav) === 0) {
      return;
    }

    if (type === "overlay") {
      ContextDispatch({
        type: "SET_CURRENT_OVERLAY_DOWNLOAD_FOLDER",
        payload: folder,
      });
    } else {
      ContextDispatch({
        type: "SET_CURRENT_IMAGE_DOWNLOAD_FOLDER",
        payload: folder,
      });
    }

    ContextDispatch({
      type: "SET_DASHBOARD_SELECTION",
      payload: [],
    });

    if (nav) {
      navigate(nav);
    }
  };

  return (
    <div
      className={
        "sidebar" + (ContextState.menuSize === "large" ? "" : " small")
      }
    >
      {ContextState.menuSize === "large" && (
        <>
          <img
            className={"logo" + (playUnoVideo ? " dark" : "")}
            src={Config.unoLogoMedium}
            onClick={(e) => {
              // if we are clicking with control key, we play the uno video
              if (e.ctrlKey) {
                setPlayUnoVideo(true);
              } else {
                navigate("/home");
              }
            }}
            alt="logo"
          />
          {playUnoVideo && (
            <video
              autoPlay
              muted
              playsInline
              className="logo-uno-video"
              onEnded={() => {
                setPlayUnoVideo(false);
              }}
            >
              <source src={getRandomVideo()} type="video/mp4" />
            </video>
          )}
        </>
      )}
      {ContextState.menuSize !== "large" && (
        <img
          className="logo"
          src={Config.unoLogoTiny}
          onClick={() => navigate("/home")}
          alt="logo"
        />
      )}
      {isAuthenticated && renderNewItem()}

      <div className="menu">
        <div
          className={classForLocation("entry", "/home")}
          onClick={() => {
            navigate("/home");
            mixpanel.track("Click Home");
            moengage.track('Click Home');
          }}
        >
          {renderEntry(<IconHome className="icon" />, "Home")}
        </div>

        <div
          className={classForLocation("entry", "/library")}
          onClick={() => {
            navigate("/library");
            mixpanel.track("Click Library");
            moengage.track('Click Library');
          }}
        >
          {renderEntry(<IconLibrary className="icon" />, "Library")}
        </div>

        {hasInbox && (
          <div
            className={classForLocation("entry", "/inbox")}
            onClick={() => {
              navigate("/inbox");
              mixpanel.track("Click Inbox");
              moengage.track('Click Inbox');
            }}
          >
            {renderEntry(<IconInbox className="icon" />, "Inbox")}
          </div>
        )}

        {isAuthenticated && (
          <>
            <div className="divider"></div>
            <div
              className={
                classForLocation("entry", "/myoverlays") + " toplevel highlight"
              }
            >
              <div
                className="icon"
                onClick={() => {
                  ContextDispatch({
                    type: "SET_CURRENT_OVERLAY_DOWNLOAD_FOLDER",
                    payload: ContextState.dashboardRootFolderOverlays,
                  });
                  navigate("/myoverlays/all");
                }}
              >
                <IconMyOverlays />
              </div>

              {ContextState.menuSize === "large" && (
                <>
                  <div
                    className="title"
                    onClick={() => {
                      ContextDispatch({
                        type: "SET_CURRENT_OVERLAY_DOWNLOAD_FOLDER",
                        payload: ContextState.dashboardRootFolderOverlays,
                      });
                      navigate("/myoverlays/all");
                    }}
                  >
                    My Overlays
                  </div>
                  <div
                    className="show"
                    onClick={() => {
                      ContextDispatch({
                        type: "SET_MENU_OVERLAY_SHOW",
                        payload: !ContextState.menuOverlayShow,
                      });
                    }}
                  >
                    {ContextState.menuOverlayShow ? (
                      <IconAngleUp />
                    ) : (
                      <IconAngleDown />
                    )}
                  </div>
                </>
              )}
            </div>

            {ContextState.menuOverlayShow && (
              <>
                <div
                  className={
                    classForLocation("entry sub", "/myoverlays/all") +
                    " highlight"
                  }
                  onClick={() => {
                    onFolderClick(
                      ContextState.dashboardRootFolderOverlays,
                      "overlay",
                      "/myoverlays/all"
                    );
                    mixpanel.track("Click My Overlays All");
                    moengage.track('Click My Overlays All');
                  }}
                >
                  {renderEntry(<IconAll className="icon" />, "All")}
                </div>
                <div
                  className={
                    classForLocation("entry sub", "/myoverlays/favorites") +
                    " highlight"
                  }
                  onClick={() => {
                    onFolderClick(
                      ContextState.dashboardRootFolderOverlays,
                      "overlay",
                      "/myoverlays/favorites"
                    );
                    mixpanel.track("Click My Overlays Favorites");
                    moengage.track('Click My Overlays Favorites');
                  }}
                >
                  {renderEntry(<IconFavorites className="icon" />, "Favorites")}
                </div>
                <div
                  className={
                    classForLocation("entry sub", "/myoverlays/trash") +
                    " highlight"
                  }
                  onClick={() => {
                    onFolderClick(
                      ContextState.dashboardRootFolderOverlays,
                      "overlay",
                      "/myoverlays/trash"
                    );
                    mixpanel.track("Click My Overlays Trash");
                    moengage.track('Click My Overlays Trash');
                  }}
                >
                  {renderEntry(<IconTrash className="icon" />, "Trash")}
                </div>

                <SidebarFolders
                  header="/myoverlays"
                  dashboardType="controlapps"
                  parentFolder={ContextState.dashboardRootFolderOverlays}
                  folders={ContextState.dashboardFoldersOverlays}
                  reloadFolders={() => {
                    FetchDataFunctions.fetchDashboardFoldersOverlays(
                      ContextState,
                      ContextDispatch
                    );
                  }}
                  show={ContextState.menuOverlayFoldersShow}
                  onShow={(value) => {
                    ContextDispatch({
                      type: "SET_MENU_OVERLAY_FOLDERS_SHOW",
                      payload: value,
                    });
                  }}
                  onFolderClick={(folder, nav) => {
                    onFolderClick(folder, "overlay", nav);
                    mixpanel.track("Click My Overlays Folder");
                    moengage.track('Click My Overlays Folder');
                  }}
                />
              </>
            )}

            <div className="divider"></div>

            <div
              className={
                classForLocation("entry", "/myimages") + " toplevel highlight"
              }
            >
              <div
                className="icon"
                onClick={() => {
                  ContextDispatch({
                    type: "SET_CURRENT_IMAGE_DOWNLOAD_FOLDER",
                    payload: ContextState.dashboardRootFolderImages,
                  });
                  navigate("/myimages/all");
                }}
              >
                <IconImage />
              </div>

              {ContextState.menuSize === "large" && (
                <>
                  <div
                    className="title"
                    onClick={() => {
                      ContextDispatch({
                        type: "SET_CURRENT_IMAGE_DOWNLOAD_FOLDER",
                        payload: ContextState.dashboardRootFolderImages,
                      });
                      navigate("/myimages/all");
                    }}
                  >
                    My Images
                  </div>
                  <div
                    className="show"
                    onClick={() => {
                      ContextDispatch({
                        type: "SET_MENU_IMAGE_SHOW",
                        payload: !ContextState.menuImageShow,
                      });
                    }}
                  >
                    {ContextState.menuImageShow ? (
                      <IconAngleUp />
                    ) : (
                      <IconAngleDown />
                    )}
                  </div>
                </>
              )}
            </div>

            {ContextState.menuImageShow && (
              <>
                <div
                  className={
                    classForLocation("entry sub", "/myimages/all") +
                    " highlight"
                  }
                  onClick={() => {
                    onFolderClick(
                      ContextState.dashboardRootFolderImages,
                      "image",
                      "/myimages/all"
                    );
                    mixpanel.track("Click My Images All");
                    moengage.track('Click My Images All');
                  }}
                >
                  {renderEntry(<IconAll className="icon" />, "All")}
                </div>
                <div
                  className={
                    classForLocation("entry sub", "/myimages/favorites") +
                    " highlight"
                  }
                  onClick={() => {
                    onFolderClick(
                      ContextState.dashboardRootFolderImages,
                      "image",
                      "/myimages/favorites"
                    );
                    mixpanel.track("Click My Images Favorites");
                    moengage.track("Click My Images Favorites");
                  }}
                >
                  {renderEntry(<IconFavorites className="icon" />, "Favorites")}
                </div>
                <div
                  className={
                    classForLocation("entry sub", "/myimages/trash") +
                    " highlight"
                  }
                  onClick={() => {
                    onFolderClick(
                      ContextState.dashboardRootFolderImages,
                      "image",
                      "/myimages/trash"
                    );
                    mixpanel.track("Click My Images Trash");
                    moengage.track("Click My Images Trash");
                  }}
                >
                  {renderEntry(<IconTrash className="icon" />, "Trash")}
                </div>

                <SidebarFolders
                  header="/myimages"
                  dashboardType="images"
                  parentFolder={ContextState.dashboardRootFolderImages}
                  folders={ContextState.dashboardFoldersImages}
                  reloadFolders={() => {
                    FetchDataFunctions.fetchDashboardFoldersImages(
                      ContextState,
                      ContextDispatch
                    );
                  }}
                  show={ContextState.menuImageFoldersShow}
                  onShow={(value) => {
                    ContextDispatch({
                      type: "SET_MENU_IMAGE_FOLDERS_SHOW",
                      payload: value,
                    });
                  }}
                  onFolderClick={(folder, nav) => {
                    onFolderClick(folder, "image", nav);
                    mixpanel.track("Click My Images Folder");
                    moengage.track("Click My Images Folder");
                  }}
                />
              </>
            )}

            <div className="divider"></div>
            <div
              className={classForLocation("entry", "/account")}
              onClick={() => {
                navigate("/account");
                mixpanel.track("Click Account");
                moengage.track("Click Account");
              }}
            >
              {renderEntry(<IconLogin className="icon" />, "Account")}
            </div>
            <div
              className={classForLocation("entry", "/subscription")}
              onClick={() => {
                navigate("/subscription");
                mixpanel.track("Click Subscription");
                moengage.track("Click Subscription");
              }}
            >
              {renderEntry(<IconCreditCard className="icon" />, "Subscription")}
            </div>

            <div
              className="entry"
              onClick={() => {
                Util.resetAllTokens(ContextDispatch);
                mixpanel.reset();
                navigate("/home");
              }}
            >
              {renderEntry(<IconLogout className="icon" />, "Logout")}
            </div>
          </>
        )}
        {!isAuthenticated && (
          <>
            <div className="divider"></div>
            <div
              className={classForLocation("entry", "/pricing")}
              onClick={() => {
                navigate("/pricing");
                mixpanel.track("Click Pricing");
                moengage.track("Click Pricing");
              }}
            >
              {renderEntry(<IconCreditCard className="icon" />, "Pricing")}
            </div>
          </>
        )}

        <div className="divider"></div>
        <div
          className="entry"
          onClick={() => {
            ContextDispatch({
              type: "SET_MENU_SIZE",
              payload: ContextState.menuSize === "large" ? "small" : "large",
            });
          }}
        >
          {renderEntry(<IconCollapse />, "Collapse")}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
