import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./styles/index.scss";
import Store from "./stores/store";

const root = createRoot(document.getElementById("root"));
root.render(
  <Store>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Store>
);
