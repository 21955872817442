import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";

import { IconSpinner, IconClose } from "../utilities/SvgIcon";
import mixpanel from "../utilities/MixPanel";
import moengage from "../utilities/MoEngage";

const DeleteAccount = ({ onClose }) => {
  const [ContextState, ContextDispatch] = useContext(Context);
  const [magicWords, setMagicWords] = useState("");
  const [busy, setBusy] = useState(false);

  const navigate = useNavigate();

  const onDeleteAccount = (event) => {
    setBusy(true);

    fetch(
      Config.singularUrl + "/apiv2/accounts/" + ContextState.userInfo.accountId,
      {
        method: "DELETE",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        mixpanel.track("Delete Account", {
          accountId: ContextState.userInfo.accountId,
        });
        moengage.track("Delete Account", {
          accountId: ContextState.userInfo.accountId,
        });
        mixpanel.reset();

        Util.handleFetchError(result, ContextDispatch, navigate);

        setBusy(false);
        setMagicWords("");

        Util.resetAllTokens(ContextDispatch);
        Util.addNotification("Your account has been deleted");
        onClose();
        navigate("/");
      })
      .catch((error) => {
        setBusy(false);
        setMagicWords("");
      });
  };

  return (
    <div className="content">
      <div className="sendfeedback-header">
        <div className="title">Delete Account</div>
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>
      <div className="message">
        After the account has been deleted, you will not able to restore your
        data!
      </div>
      <div className="form">
        <div className="label-input">
          <div className="label">
            Enter{" "}
            <b>
              <span translate="no">"Delete Account"</span>
            </b>{" "}
            below to continue
          </div>
          <input
            type="text"
            className="input"
            value={magicWords}
            onChange={(e) => setMagicWords(e.target.value)}
            autoFocus
          />
        </div>
      </div>
      <button
        className="button danger"
        value="Delete Account"
        onClick={onDeleteAccount}
        disabled={magicWords !== "Delete Account"}
      >
        {!busy && "Delete Account"}
        {busy && <IconSpinner />}
      </button>
    </div>
  );
};

export default DeleteAccount;
