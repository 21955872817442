import React, { useEffect, useState, useContext, useRef } from "react";
import { Context } from "../stores/store";
import { IconSpinner } from "../utilities/SvgIcon";

import Util from "../utilities/Util";

const ADVERTISEMENT_UPDATE_INTERVAL = 60000;
const ADVERTISEMENT_UPDATE_INTERVAL_DELTA = 30000;

// these are the google ad slots that we will use, they are randomly shuffled
const GOOGLE_AD_SLOT_IDS = [
  "2889999909",
  "6170181000",
  "5922768991",
  "3056466222",
  "8880710377",
  "4511318007",
  "5612646684",
  "4732478152",
  "8024887726",
  "8100877528",
];

const Ads = ({ layout, direction, numberOfAds, onShowDialog }) => {
  // how often should we reload the ads?
  const calculateAdRefreshInterval = () => {
    return (
      ADVERTISEMENT_UPDATE_INTERVAL +
      Math.round(Math.random() * ADVERTISEMENT_UPDATE_INTERVAL_DELTA)
    );
  };

  // calculate an array if a goggole ad should be displayed with 50% of the time
  const calculateGoogleAdFlags = () => {
    return new Array(20).fill(0).map(() => Math.random() < 0.5);
  };

  const shuffleGoogleAdIds = () => {
    return [...GOOGLE_AD_SLOT_IDS].sort(() => Math.random() - 0.5);
  };

  const [ContextState, ContextDispatch] = useContext(Context);
  const [forceRedraw, setForceRedraw] = useState(0);
  const [adsToRender, setAdsToRender] = useState([]);
  const [randomizedAds, setRandomizedAds] = useState([]);
  const [googleAdsLibrayLoaded, setGoogleAdsLibrayLoaded] = useState(false);
  const googleAdFlags = useRef(calculateGoogleAdFlags());
  const googleAdIds = useRef(shuffleGoogleAdIds());

  // load the google ads library
  useEffect(() => {
    if (window.adsbygoogle) {
      return;
    }
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9826658694170953";
    script.async = true;
    script.onload = function () {
      setGoogleAdsLibrayLoaded(true);
    };

    // handle the error
    script.onerror = function () {
      console.log("Error loading AdSense library");
      setGoogleAdsLibrayLoaded(true);
    };
    document.head.appendChild(script);
  }, []);

  // initialize AdSense and set the interval to redraw the ads
  useEffect(() => {
    // display the ads in a random order
    const intervalHandler = setInterval(() => {
      setForceRedraw((prev) => prev + 1);
    }, calculateAdRefreshInterval());
    return () => clearInterval(intervalHandler);
  }, []);

  // When the uno ads change we need to randomize them
  useEffect(() => {
    if (
      ContextState.unoAdsLoaded === false ||
      !ContextState.unoAds ||
      !ContextState.unoAds.ads
    ) {
      setRandomizedAds([]);
      return;
    }

    const randomAds = [...ContextState.unoAds.ads];
    randomAds.sort(() => Math.random() - 0.5);
    setRandomizedAds(randomAds);

    googleAdFlags.current = calculateGoogleAdFlags();
    googleAdIds.current = shuffleGoogleAdIds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.unoAds, ContextState.unoAdsLoaded, forceRedraw]);

  // randomize the ads after they have been loaded
  useEffect(() => {
    if (!randomizedAds || !randomizedAds.length) {
      setAdsToRender([]);
      return;
    }

    // the result will be stored here
    const ads = [];

    // now add the reference to the google ads
    let googleAdIndex = 0;
    for (let i = 0; i < numberOfAds; i++) {
      // make a copy of the ad
      const newAd = { ...randomizedAds[i % randomizedAds.length] };

      // use the static array to determine if a google ad should be displayed
      const flags = googleAdFlags.current;
      const ids = googleAdIds.current;
      if (flags[i % flags.length]) {
        newAd.googleAdSlot = ids[googleAdIndex % ids.length];
        googleAdIndex++;
      } else {
        newAd.googleAdSlot = null;
      }

      // add the ad to the final list
      ads.push(newAd);
    }

    setAdsToRender(ads);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomizedAds, numberOfAds, forceRedraw]);

  // reset the ads after the redraw
  useEffect(() => {
    // if I don't have uno ads yet or the google ads library is not loaded
    if (adsToRender.length === 0 || googleAdsLibrayLoaded === false) {
      return;
    }

    // get the number of google ads that are active
    const activeGoogleAds = adsToRender.filter((ad) => ad.googleAdSlot).length;

    // initialize the google ads
    for (let i = 0; i < activeGoogleAds; i++) {
      setTimeout(() => {
        try {
          if (window) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        } catch (e) {
          console.log("Error initializing AdSense", i, e);
        }
      }, 1000 * i);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    googleAdsLibrayLoaded,
    forceRedraw,
    adsToRender.length,
    ContextState.unoAdsLoaded,
  ]);

  // render an ad slot
  const renderAdSlot = (ad, index) => {
    const renderUnoAd = () => {
      return (
        <div
          key={index}
          className={"uno-ad" + (ad.link ? " link" : "")}
          onClick={() => {
            if (ad.link) {
              Util.openUrl(ad.link);
            }
          }}
        >
          <img
            className="image"
            src={ad.imageUrl}
            alt={ad.imageAlt || "Advertisement " + index}
          />
          {ad.title && <div className="title">{ad.title}</div>}
          {ad.message && <div className="message">{ad.message}</div>}
        </div>
      );
    };

    const renderGoogleAd = () => {
      if (!ad.googleAdSlot) {
        return null;
      }

      return (
        <ins
          key={"adsbygoogle-" + forceRedraw + "-" + index}
          className="adsbygoogle"
          style={{
            paddingRight: layout === "horizontal" ? "10px" : "0px",
          }}
          data-ad-client="ca-pub-9826658694170953"
          data-ad-slot={ad.googleAdSlot}
          data-ad-format="fixed"
          data-full-width-responsive="false"
        ></ins>
      );
    };

    // this is for testing the page locally. It will show a red box instead of the ad
    // const renderPlaceholder = () => {
    //   if (!ad.googleAdSlot) {
    //     return null;
    //   }

    //   return (
    //     <div
    //       key={"adsbygoogle-" + forceRedraw + "-" + index}
    //       className="google-ads-placeholder"
    //       style={{ background: "red" }}
    //     >
    //       {ad.googleAdSlot}
    //     </div>
    //   );
    // };

    return (
      <div key={index} className="good-stuff-slot">
        {renderGoogleAd()}
        {/* {renderPlaceholder()} */}
        {renderUnoAd()}
      </div>
    );
  };

  // render all the ads, but only if they are loaded
  const renderAds = () => {
    if (ContextState.unoAdsLoaded === false) {
      return (
        <div className="loading">
          <IconSpinner />
        </div>
      );
    }

    return (
      <>
        {adsToRender.map((ad, index) => {
          return renderAdSlot(ad, index);
        })}
      </>
    );
  };

  const renderWhyAmISeeingAds = () => {
    return (
      <div
        className="good-stuff-link"
        onClick={() => {
          Util.openUrl(
            "https://resources.overlays.uno/post/uno-new-release-2025#why-am-i-seeing-ads"
          );
        }}
      >
        Why am I seeing ads?
      </div>
    );
  };

  const renderRemoveAds = () => {
    // this will keep the space. If we are not authenticated
    // nothing will be rendered
    if (ContextState.authenticationStatus === "busy") {
      return <div className="remove-good-stuff"></div>;
    }

    return (
      <div className="remove-good-stuff">
        {direction === "horizontal" ? <>{renderWhyAmISeeingAds()}</> : null}
        Want to enjoy an ad-free experience?
        <div
          className="good-stuff-link"
          onClick={() => {
            if (ContextState.authenticationStatus !== "ok") {
              onShowDialog("login");
            } else {
              ContextDispatch({
                type: "SET_GLOBAL_NAVIGATION",
                payload: "subscription",
              });
            }
          }}
        >
          Remove Ads
        </div>
      </div>
    );
  };

  // render the ads
  return (
    <div className={"good-stuff " + layout}>
      <div className="good-stuff-title">
        Sponsored
        {renderWhyAmISeeingAds()}
      </div>
      <div className="uno-good-stuff">{renderAds()}</div>
      {renderRemoveAds()}
    </div>
  );
};

export default Ads;
