import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Context } from "../stores/store";

import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import LibraryListItem from "./LibraryListItem";
import { IconSearch, IconSort } from "../utilities/SvgIcon";

const propTypes = {
  items: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

const LibraryList = ({
  items,
  onFilterChanged,
  onSelectItem,
}) => {
  const sortOptions = [
    {
      id: "popular",
      name: "Popular",
    },
    {
      id: "trending",
      name: "Trending",
    },
    {
      id: "alphaAZ",
      name: "A to Z",
    },
    {
      id: "changed91",
      name: "Recent",
    },
  ];
  const [ContextState, ContextDispatch] = useContext(Context);

  // show a message if there are no items
  if (items.length === 0) {
    return (
      <div className="library-list">
        <div className="message">
          <IconSearch className="icon" />
          <div className="title">No Results</div>
          <div className="info">
            Sorry, there were no results that match your search or filters.
          </div>
        </div>
      </div>
    );
  }

  // if we have less than 4 elements in the list then it looks silly. Just add empty divs to make it right
  let filler = [];
  if (items.length < 4) {
    filler = Array.apply(null, Array(4 - items.length)).map(function (x, i) {
      return i;
    });
  }

  return (
    <div className="library-list">
      <div className="library-header">
        Library
        <div className="offset"></div>
        <div className="header-menu">
          <Menu
            menuButton={
              <MenuButton className="button">
                <>
                  <IconSort />
                  {
                    sortOptions.find(
                      (item) => item.id === ContextState.librarySort
                    ).name
                  }
                </>
              </MenuButton>
            }
            transition
            align="end"
            menuClassName="menu-background"
            offsetY={2}
          >
            {sortOptions.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    ContextDispatch({
                      type: "SET_MY_LIBRARY_SORT",
                      payload: item.id,
                    });
                  }}
                  className="menu-item"
                >
                  <div>{item.name}</div>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <span className="search-stats">{items.length} results</span>
      </div>
      <div className="content-wrapper">
        <div className="content">
          {items.map((item) => {
            return (
              <LibraryListItem
                key={item.id}
                item={item}
                onSelectItem={onSelectItem}
                onFilterChanged={onFilterChanged}
              />
            );
          })}
          {filler.map((item, index) => {
            return <div key={"f" + index}></div>;
          })}
        </div>
      </div>
    </div>
  );
};

LibraryList.propTypes = propTypes;

export default LibraryList;
