import React from "react";

import { IconSubscriptionCheck } from "../utilities/SvgIcon";

const FeatureComparison = ({
  getCurrentPlan,
  getPlanName,
  getPlanPrice,
  onChangePlan,
}) => {
  const renderLine = (a, b, c, d, e) => {
    return (
      <>
        <div className="feature-comparison-table-cell first">{a}</div>
        <div className="feature-comparison-table-cell">{b}</div>
        <div className="feature-comparison-table-cell">{c}</div>
        {/* <div className="feature-comparison-table-cell">{d}</div>
        <div className="feature-comparison-table-cell">{e}</div> */}
      </>
    );
  };

  const renderStartNow = (plan) => {
    const current = getCurrentPlan();

    if (current === plan || current === "singular") {
      return <div className="start">&nbsp;</div>;
    }

    return (
      <div className="start" onClick={() => onChangePlan(plan)}>
        Start now
      </div>
    );
  };

  // show the subscription plans
  return (
    <div className="feature-comparison">
      <div className="feature-comparison-header">Feature comparison</div>

      <div className="feature-comparison-table">
        <div className="feature-comparison-table-header-cell"></div>
        <div className="feature-comparison-table-header-cell">
          {getPlanName("free")}
        </div>
        <div className="feature-comparison-table-header-cell">
          {getPlanName("unoplus")}
          {renderStartNow("unoplus")}
        </div>
        {/*
        <div className="feature-comparison-table-header-cell">
          {getPlanName("unopro")}
          {renderStartNow("unopro")}
        </div>
        <div className="feature-comparison-table-header-cell">
          <div className="line">
            {getPlanName("unoelite")}
            <div className="badge">Enterprise</div>
          </div>

          {renderStartNow("unoelite")}
        </div>
        */}

        {renderLine(
          "Price",
          "Free",
          "$ " + getPlanPrice("unoplus") + " / month",
          "$ " + getPlanPrice("unopro") + " / month",
          "$ " + getPlanPrice("unoelite") + " / month"
        )}
        {renderLine(
          "Ad-free",
          "",
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />
        )}
        {renderLine(
          "Display variants",
          "",
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />
        )}
        {renderLine(
          "Edit presets",
          "",
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />
        )}
        {renderLine("Storage", "25 MB", "100 MB", "1 GB", "5 GB")}
        {renderLine("API calls per day", "5 k", "10 k", "20 k", "100 k")}
        {/*
        {renderLine(
          "Folders",
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />
        )}
        {renderLine(
          "Data integration",
          "",
          "",
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />
        )}
        {renderLine(
          "Fonts selection",
          "",
          "",
          <IconSubscriptionCheck />,
          <IconSubscriptionCheck />
        )}
        {renderLine("Teams", "", "", "", <IconSubscriptionCheck />)}
      */}
      </div>
    </div>
  );
};

export default FeatureComparison;
