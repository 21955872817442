import Util from "../utilities/Util";
import {
  IconFilterLayout,
  IconLayoutPortrait,
  IconLayoutLandscape,
  IconLayoutSquare,
} from "../utilities/SvgIcon";

const Tags = ({ item, size, filter, onSelectFilter }) => {
  const handleClick = (e, type, value) => {
    if (onSelectFilter) {
      onSelectFilter(e, type, value);
    }
  };
  if (!item) {
    return <div className="tags"></div>;
  }

  let tagsClassName = "tags";
  if (size === "small") {
    tagsClassName += " small";
  }
  if (onSelectFilter) {
    tagsClassName += " can-click";
  }

  const variants = Util.getLibraryItemDisplayVariants(item);

  // show only the layout tags
  const renderLayout = () => {
    const getLayoutIcon = (layout) => {
      switch (layout) {
        case "Landscape":
          return <IconLayoutLandscape />;
        case "Portrait":
          return <IconLayoutPortrait />;
        case "Square":
          return <IconLayoutSquare />;
        case "Responsive":
          return <IconFilterLayout />;
        default:
          return null;
      }
    };

    const getLayoutAspectRatio = (layout) => {
      switch (layout) {
        case "Landscape":
          return "16:9";
        case "Portrait":
          return "9:16";
        case "Square":
          return "1:1";
        default:
          return "";
      }
    };

    return (
      <>
        {["Landscape", "Portrait", "Square", "Responsive"].map((layout) => {
          const numberOfVariants = Util.getNumberOfVariantsForLayout(
            variants,
            layout
          );
          if (numberOfVariants) {
            return (
              <div
                key={layout}
                className="tag"
                onClick={(e) => handleClick(e, "tag_layout", layout)}
              >
                {numberOfVariants > 1 ? `${numberOfVariants}X` : ""}
                {getLayoutIcon(layout)}
                {getLayoutAspectRatio(layout)}
              </div>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  };

  if (filter === "tag_layout") {
    return <div className={tagsClassName}>{renderLayout()}</div>;
  }

  return (
    <div className={tagsClassName}>
      {item.tag_type && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_type", item.tag_type)}
        >
          {/* <IconFilterType /> */}
          {item.tag_type}
        </div>
      )}
      {item.tag_function && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_function", item.tag_function)}
        >
          {/* <IconFilterFunction /> */}
          {item.tag_function}
        </div>
      )}
      {item.tag_category && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_category", item.tag_category)}
        >
          {/* <IconFilterCategory /> */}
          {item.tag_category}
        </div>
      )}
      {item.tag_theme && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_theme", item.tag_theme)}
        >
          {/* <IconFilterTheme /> */}
          {item.tag_theme}
        </div>
      )}
      {!filter && renderLayout()}
      {item.tag_datasource && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_datasource", item.tag_datasource)}
        >
          {/* <IconFilterDataSource /> */}
          {item.tag_datasource}
        </div>
      )}
    </div>
  );
};

export default Tags;
