import moengage from "@moengage/web-sdk";
const isProduction = process.env.NODE_ENV === 'production'

const mx = {
  track: (e, o) => {
    !isProduction && console.log('motrack *****', e)
    moengage.track_event(e, o);
  },
  identify: (user_id, user_email) => {
    !isProduction && console.log('moidentify *****', user_id, user_email)
    moengage.add_unique_user_id(user_id);
    moengage.add_email(user_email);
  },
  destroy_session: () => {
    !isProduction && console.log('modestroy *****')
    moengage.destroy_session();
  },
  // track_pageview: (o) => {
  //   !isProduction && console.log('track_pageview');
  //   mixpanel.track_pageview(o);
  // }
};

export default mx; 