import React, { useState, useEffect, useRef, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";

import "./styles/index.scss";

import { Context } from "./stores/store";
import Config from "./stores/Config";
import Util from "./utilities/Util";
import FetchDataFunctions from "./utilities/FetchDataFunctions";

import { IconSpinner, IconBrowserResize } from "./utilities/SvgIcon";

import AppLocalStorage from "./utilities/AppLocalStorage.js";

import Login from "./features/Login";
import SendFeedback from "./features/SendFeedback";
import DeleteAccount from "./features/DeleteAccount";
import VerifyEmail from "./features/VerifyEmail";
import ForgotPassword from "./features/ForgotPassword";
import ResetPassword from "./features/ResetPassword";
import ResetPasswordHelper from "./features/ResetPasswordHelper";
import Account from "./features/Account";
import Subscription from "./features/Subscription.jsx";
import Home from "./features/Home";
import DashboardMyOverlays from "./features/DashboardMyOverlays";
import DashboardMyImages from "./features/DashboardMyImages";
import Library from "./features/Library";
import LibraryDetails from "./features/LibraryDetails";
import DashboardInbox from "./features/DashboardInbox";
import SingularInfo from "./features/SingularInfo";
import UploadImage from "./features/UploadImage";
import StripeCheckout from "./features/StripeCheckout";

import Error from "./features/Error";

import PageFooter from "./features/PageFooter";
import Main from "./features/Main";
import Banner from "./features/Banner";
import Ads from "./features/Ads";

import mixpanel from "./utilities/MixPanel.js";
import moengage from "./utilities/MoEngage.js";

// initialize the modal library
Modal.setAppElement("#root");

// create a transition for tostify
const Fade = cssTransition({
  enter: "animate__animated animate__fadeInDown animate__faster",
  exit: "animate__animated animate__fadeOutUp animate__faster",
});

const getCSSVariable = (variable) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable);
};

// constants for the ads
const BROWSER_WIDTH_THRESHOLD = 1200;
const BROWSER_WIDTH_SMALL_THRESHOLD = 850;
const BROWSER_HEIGHT_THRESHOLD = 1000;
const ADS_WIDTH = parseInt(getCSSVariable("--sl-google-ads-width")) || 300;
const ADS_WIDTH_SMALL =
  parseInt(getCSSVariable("--sl-google-ads-width-small")) || 150;
const ADS_HEIGHT = parseInt(getCSSVariable("--sl-google-ads-height")) || 250;
const ADS_HEIGHT_SMALL =
  parseInt(getCSSVariable("--sl-google-ads-height-small")) || 125;

const BROWSER_TOO_SMALL_WIDTH_THRESHOLD = 600;

// this is a bit a hack, but the easiest way to get parameters from the initial
// url the page was loaded with. If I do this in the App function
// every navigate will erase the default Email
const initialEmail = decodeURIComponent(Util.getUrlSearchParameter("email"));

const App = () => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ContextState, ContextDispatch] = useContext(Context);

  const [dialogState, setDialogState] = useState("");
  const [imageDialogAllowClose, setImageDialogAllowClose] = useState(true);

  const [libraryDetails, setLibraryDetails] = useState();
  const [libraryFilter, setLibraryFilter] = useState({});
  const [searchMyOverlays, setSearchMyOverlays] = useState("");
  const [searchMyImages, setSearchMyImages] = useState("");
  const [searchLibrary, setSearchLibrary] = useState("");
  const [searchInbox, setSearchInbox] = useState("");
  const [passwordResetToken, setPasswordResetToken] = useState("");
  const [stripeProductId, setStripeProductId] = useState("");
  const [stripeProductName, setStripeProductName] = useState("");
  const [inboxFolderHasContent, setInboxFolderHasContent] = useState(false);

  const [windowWidth, setWindowWidth] = useState(BROWSER_WIDTH_THRESHOLD + 1);
  const [windowHeight, setWindowHeight] = useState(
    BROWSER_HEIGHT_THRESHOLD + 1
  );
  const observedDiv = useRef(null);

  // set up the observer to track the width of the div
  useEffect(() => {
    // track the width of the div
    const handleElementResized = () => {
      if (observedDiv.current) {
        if (observedDiv.current.offsetWidth !== windowWidth) {
          setWindowWidth(observedDiv.current.offsetWidth);
        }
        if (observedDiv.current.offsetHeight !== windowHeight) {
          setWindowHeight(observedDiv.current.offsetHeight);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleElementResized);
    resizeObserver.observe(observedDiv.current);
    return () => resizeObserver.disconnect();
  });

  // this is executed once after the app is loaded
  useEffect(() => {
    // detect when we lose focus
    window.addEventListener("blur", () => {
      // hide all active tooltips
      ReactTooltip.show();
    });

    window.addEventListener(
      "beforeunload",
      (e) => {
        if (imageDialogAllowClose) {
          return null;
        }

        e.preventDefault();
        return "Image upload is active. Are you sure you want to quit?";
      },
      false
    );

    // initialize local storage
    AppLocalStorage.load(Config.localStorageFileName, ContextDispatch);

    // check if we have a token in the webpage
    if (window.unoInfo && Object.keys(window.unoInfo).length) {
      if (window.unoInfo.success) {
        if (window.unoInfo.accessToken) {
          ContextDispatch({
            type: "SET_UNO_ACCESS_TOKEN",
            payload: window.unoInfo.accessToken,
          });
          ContextDispatch({
            type: "SET_UNO_REFRESH_TOKEN",
            payload: window.unoInfo.refreshToken,
          });
        }
      } else {
        Util.addNotification("Failed to login with Google", "error");
      }
    }

    // check if there is deep link data in the webpage. If it is then open the dialog
    if (window.unoDeeplink && window.unoDeeplink.content) {
      // we need to convert the deep link into an object
      try {
        // convert the deep link into an object that we can use in the library details dialog
        window.unoDeeplink.content = JSON.parse(window.unoDeeplink.content);
        const deeplink = {
          item: window.unoDeeplink,
          nextUrl: null,
          previousUrl: null,
          isDeepLink: true,
        };

        // open the library details dialog
        setDialogState("librarydetails");

        // set the library details so we dialog knows what to show
        setLibraryDetails(deeplink);
      } catch (e) {
        console.log("error parsing deep link", e);
      }
    }

    // now we are ready to use the data that was read from local storage
    setIsInitializing(false);

    document.body.dataset.theme = ContextState.theme;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.dataset.theme = ContextState.theme;
  }, [ContextState.theme]);

  // a function to simplify dispatching data into the context
  const dispatch = (type, data) => {
    ContextDispatch({
      type: type,
      payload: data,
    });
  };

  // when the access token changes we have to check if it is valid
  useEffect(() => {
    if (ContextState.storageLoaded === false) {
      return;
    }

    if (ContextState.unoAccessToken) {
      dispatch("SET_AUTHENTICATION_STATUS", "busy");

      // fetch the user data
      const controller = new AbortController();
      fetch(Config.singularUrl + "/apiv2/users/me", {
        signal: controller.signal,
        method: "GET",
        headers: Util.getFetchHeaders(ContextState),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.error) {
            throw data;
          }

          // for testing only
          // data.subscriptionPlan = Util.getSubscriptionPlan(data);

          dispatch("SET_USER_INFO", data);
          // tell the user that the user is not verified
          if (data.emailVerified === false) {
            dispatch("SET_AUTHENTICATION_STATUS", "notverified");
            setDialogState("verifyemail");
          } else {
            // everything ok
            dispatch("SET_AUTHENTICATION_STATUS", "ok");

            // this would normally not be necessary, but we have to set the isAuthenticated here
            // otherwise the "Add to my Overlays" button will show "Try Overlays" for a brief moment.
            // the reason is the useEffect that listens to ContextState.authenticationStatus
            setIsAuthenticated(true);

            // I think the reason we are using ContextState.userInfo.accountId instead of data.accountId
            // line 187 sets the user info in ContextState, but that data is not accessible immediately. It it like
            // setState({}) in the old react.
            mixpanel.identify(data.accountId);

            // Moengage
            moengage.identify(data.id, data.email);
            moengage.track("User Logged In");

            // if the brand is singular and we have a free subscription then show a dialog, but only show it once
            if (
              data.brand === "singular" &&
              data.subscriptionPlan === "free" &&
              data.watermark === true
            ) {
              // check if we already showed the dialog
              const show = AppLocalStorage.loadValue("showSingularInfo", "1");
              if (show === "1") {
                setDialogState("singularinfo");
                // don't show the dialog again
                AppLocalStorage.saveValue("showSingularInfo", "0");
              }
            }

            // if we have valid subscription check the inbox content
            const url =
              Config.singularUrl +
              "/apiv2/dashboard/folder/inbox/items?type=controlapp";

            // fetch the items
            fetch(url, {
              method: "GET",
              headers: Util.getFetchHeaders(ContextState),
            })
              .then((res) => res.json())
              .then((result) => {
                if (result.data && result.data.length) {
                  setInboxFolderHasContent(true);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((data) => {
          // there was an error so erase the user info and access token
          Util.resetAllTokens(ContextDispatch);

          // don't show the error if the user aborted the request or the status is 401
          if (data && data.error && data.error.code === 401) {
            return;
          }
          if (controller.signal.aborted) {
            return;
          }

          Util.addNotification(
            "Error while confirming authentication",
            "error"
          );
        });
      return () => {
        controller.abort();
      };
    } else {
      // there is no token so we don't have the user data
      dispatch("SET_AUTHENTICATION_STATUS", "none");
      dispatch("SET_USER_INFO", "");

      moengage.track("User Logged Out");
      moengage.destroy_session();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ContextState.unoAccessToken,
    ContextState.storageLoaded,
    ContextState.forceUserReload,
  ]);

  useEffect(() => {
    if (ContextState.authenticationStatus === "ok") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.authenticationStatus]);

  // save the data to local storage, storage and app payload
  useEffect(() => {
    if (isInitializing) {
      return;
    }
    AppLocalStorage.save(Config.localStorageFileName, ContextState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState]);

  // load the content library data
  useEffect(() => {
    // get the query string
    const urlParams = new URLSearchParams(window.location.search);

    // extract the subdomain from the hostname
    const parts = window.location.hostname.split(".");
    let subdomain = parts.length > 2 ? parts[0].toLowerCase() : "";
    if (subdomain === "www") {
      subdomain = "";
    }

    // check for a subdomain in the query string and use that one instead
    let forceRefresh = false;
    if (urlParams.has("subdomain")) {
      subdomain = urlParams.get("subdomain").toLowerCase();
      forceRefresh = true;
    }

    // set the subdomain
    dispatch("SET_SUB_DOMAIN", subdomain);

    // fetch the portal data
    const controllerPortalConfig = FetchDataFunctions.fetchPortalConfig(
      ContextState,
      ContextDispatch,
      subdomain,
      forceRefresh
    );

    const controllerCustomAds = FetchDataFunctions.fetchCustomAds(
      ContextState,
      ContextDispatch
    );

    const controllerLibraryItems = FetchDataFunctions.fetchLibraryItems(
      ContextState,
      ContextDispatch,
      subdomain,
      urlParams.has("showDraft")
    );
    const controllerTopDownloaded = FetchDataFunctions.fetchTopDownloaded(
      ContextState,
      ContextDispatch
    );
    const controllerTrending = FetchDataFunctions.fetchTrending(
      ContextState,
      ContextDispatch
    );

    return () => {
      if (controllerPortalConfig) {
        controllerPortalConfig.abort();
      }
      if (controllerCustomAds) {
        controllerCustomAds.abort();
      }
      if (controllerLibraryItems) {
        controllerLibraryItems.abort();
      }
      if (controllerTopDownloaded) {
        controllerTopDownloaded.abort();
      }
      if (controllerTrending) {
        controllerTrending.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load the dashboard root folders
  useEffect(() => {
    const controller = FetchDataFunctions.fetchDashboardRootFolders(
      ContextState,
      ContextDispatch
    );
    return () => {
      if (controller) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.authenticationStatus]);

  // load the dashboard overlay folders
  useEffect(() => {
    const controller = FetchDataFunctions.fetchDashboardFoldersOverlays(
      ContextState,
      ContextDispatch
    );
    return () => {
      if (controller) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.dashboardRootFolderOverlays]);

  // load the dashboard image folders
  useEffect(() => {
    const controller = FetchDataFunctions.fetchDashboardFoldersImages(
      ContextState,
      ContextDispatch
    );
    return () => {
      if (controller) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.dashboardRootFolderImages]);

  const renderAuthenticatedRoute = (
    authenticatedChild,
    notAuthenticatedChild
  ) => {
    if (ContextState.authenticationStatus === "ok") {
      return authenticatedChild;
    }
    if (ContextState.authenticationStatus === "busy") {
      return (
        <div className="full-screen-panel">
          <IconSpinner />
        </div>
      );
    }
    return notAuthenticatedChild;
  };

  const renderInboxRoute = () => {
    if (ContextState.authenticationStatus === "ok") {
      if (Util.getHasInbox(ContextState.userInfo) && inboxFolderHasContent) {
        return (
          <DashboardInbox
            search={searchInbox}
            isAuthenticated={isAuthenticated}
            dialogState={dialogState}
            filter={libraryFilter}
            onFilterChanged={onLibraryFilterChanged}
            onShowDialog={setDialogState}
            onSetLibraryDetails={setLibraryDetails}
          />
        );
      } else {
        return <Navigate replace to="/home" />;
      }
    } else {
      return null;
    }
  };

  const onLibraryFilterChanged = (action, property, value) => {
    if (action === "clear") {
      setLibraryFilter({});
      setSearchLibrary("");
    }

    if (action === "setAll") {
      setLibraryFilter(value);
      setSearchLibrary("");
    }

    if (action === "set" || action === "clearAndSet") {
      let copy = {};
      if (action === "set") {
        copy = JSON.parse(JSON.stringify(libraryFilter));
      }
      if (Array.isArray(value)) {
        copy[property] = value;
      } else {
        copy[property] = [value];
      }
      setSearchLibrary("");
      setLibraryFilter(copy);
      if ((Array.isArray(value) && value.length) || !value) {
        writeActionLog({ action: "tags", type: property, value: value });
      }
    }

    if (action === "add") {
      let copy = JSON.parse(JSON.stringify(libraryFilter));
      let c = copy[property];
      if (c && c.length) {
        copy[property] = c.concat(value.filter((i) => c.indexOf(i) < 0));
      } else {
        copy[property] = value;
      }
      setLibraryFilter(copy);
      writeActionLog({ action: "tags", type: property, value: value });
    }

    if (action === "toggle") {
      let copy = JSON.parse(JSON.stringify(libraryFilter));
      let c = copy[property];

      // if we have an array the add or remove the value
      if (c && c.length) {
        let i = c.indexOf(value);
        if (i === -1) {
          c.push(value);
          writeActionLog({ action: "tags", type: property, value: value });
        } else {
          c.splice(i, 1);
        }
        copy[property] = c;
      } else {
        copy[property] = [value];
        writeActionLog({ action: "tags", type: property, value: value });
      }
      setLibraryFilter(copy);
    }
  };

  const writeActionLog = (data) => {
    if (!data) {
      return;
    }

    // add user data
    if (isAuthenticated) {
      data.user = ContextState.userInfo.email;
      data.accountId = ContextState.userInfo.accountId;
    } else {
      data.user = "not logged in";
      data.accountId = "not logged in";
    }

    let payload;
    const action = data.action;
    if (action === "search") {
      payload = {
        event: "Uno library search",
        data: {
          // distinct_id: data.accountId,
          text: String(data.string).toLowerCase().trim(),
        },
      };
    } else if (action === "promo") {
      payload = {
        event: "Uno home promo",
        data: {
          //distinct_id: data.accountId,
          type: data.type,
          value: data.value,
        },
      };
    } else if (action === "tags") {
      payload = {
        event: "Uno library action",
        data: {
          // distinct_id: data.accountId,
          type: data.type,
          value: data.value,
        },
      };
    } else {
      console.log("invalid action", action);
    }

    if (payload) {
      mixpanel.track(payload.event, payload.data);
      moengage.track(payload.event, payload.data);
    }
  };

  const onSearchLibrary = (search) => {
    // record the search string
    if (search) {
      writeActionLog({
        action: "search",
        string: String(search).toLowerCase(),
      });
    }
    setSearchLibrary(search);
  };
  const onSearchMyOverlays = (search) => {
    setSearchMyOverlays(search);
  };
  const onSearchMyImages = (search) => {
    setSearchMyImages(search);
  };
  const onSearchInbox = (search) => {
    setSearchInbox(search);
  };

  const renderDialogs = () => {
    // the image upload dialog is special
    if (dialogState === "uploadimage") {
      const checkCloseRequest = () => {
        if (imageDialogAllowClose) {
          setDialogState("");
        } else {
          // show the message that the user has to wait
          Util.addNotification(
            "Please wait for image upload to finish",
            "error"
          );
        }
      };

      return (
        <Modal
          className={"modal-dialog " + dialogState}
          overlayClassName={"modal-overlay "}
          isOpen={true}
          onRequestClose={checkCloseRequest}
          shouldCloseOnOverlayClick={true}
          aria={{
            label: dialogState,
          }}
        >
          <UploadImage
            isAuthenticated={isAuthenticated}
            onAllowClose={setImageDialogAllowClose}
            onClose={checkCloseRequest}
          />
        </Modal>
      );
    }

    // handle all the other dialogs
    if (
      dialogState === "login" ||
      dialogState === "signup" ||
      dialogState === "forgotpassword" ||
      dialogState === "resetpassword" ||
      dialogState === "verifyemail" ||
      dialogState === "sendfeedback" ||
      dialogState === "deleteaccount" ||
      dialogState === "librarydetails" ||
      dialogState === "singularinfo" ||
      dialogState === "stripecheckout"
    ) {
      return (
        <Modal
          className={"modal-dialog " + dialogState}
          overlayClassName={"modal-overlay "}
          isOpen={true}
          onRequestClose={() => setDialogState("")}
          shouldCloseOnOverlayClick={true}
          aria={{
            label: dialogState,
          }}
        >
          {dialogState === "login" && (
            <Login
              initialEmail={initialEmail}
              onShowDialog={setDialogState}
              onClose={() => setDialogState("")}
            />
          )}
          {dialogState === "signup" && (
            <Login
              initialEmail={initialEmail}
              initialMode="signup"
              onShowDialog={setDialogState}
              onClose={() => setDialogState("")}
            />
          )}
          {dialogState === "forgotpassword" && (
            <ForgotPassword onClose={() => setDialogState("")} />
          )}
          {dialogState === "resetpassword" && (
            <ResetPassword
              passwordResetToken={passwordResetToken}
              onClose={() => setDialogState("")}
            />
          )}
          {dialogState === "verifyemail" && (
            <VerifyEmail onClose={() => setDialogState("")} />
          )}
          {dialogState === "sendfeedback" && (
            <SendFeedback
              onShowDialog={setDialogState}
              onClose={() => setDialogState("")}
            />
          )}
          {dialogState === "deleteaccount" && (
            <DeleteAccount onClose={() => setDialogState("")} />
          )}
          {dialogState === "librarydetails" && (
            <LibraryDetails
              details={libraryDetails}
              isInitializing={isInitializing}
              isAuthenticated={isAuthenticated}
              onFilterChanged={onLibraryFilterChanged}
              onClose={() => setDialogState("")}
            />
          )}
          {dialogState === "singularinfo" && (
            <SingularInfo
              onShowDialog={setDialogState}
              onClose={() => setDialogState("")}
            />
          )}
          {dialogState === "stripecheckout" && (
            <StripeCheckout
              productId={stripeProductId}
              productName={stripeProductName}
              onClose={() => setDialogState("")}
            />
          )}
        </Modal>
      );
    }
  };

  // handle the ads layout
  let showAdsClass = "ads-hidden";
  let showAds = false;
  let isTooSmall = false;
  let adsLayout = "hidden";
  let numberOfAds = 0;
  let direction = "";

  // use the router to check if I am on the home page
  // if I am on the home page then show the ads
  const location = useLocation();
  let isHomePage = false;
  if (location.pathname === "/home" || location.pathname === "/") {
    isHomePage = true;
  }

  const subscriptionPlan = Util.getSubscriptionPlan(ContextState.userInfo);

  // the screen is too small
  if (windowWidth < BROWSER_TOO_SMALL_WIDTH_THRESHOLD) {
    isTooSmall = true;
  }

  // show the ads if the user is not logged in or has a free subscription
  if (
    ContextState.authenticationStatus !== "busy" &&
    !isHomePage &&
    subscriptionPlan === "free"
  ) {
    // switch horizontal and vertical ads based on the window size
    if (windowWidth >= BROWSER_WIDTH_THRESHOLD) {
      showAds = true;
      showAdsClass = "the-best-vertical";
      adsLayout = "vertical";
      direction = "vertical";
      numberOfAds = Math.floor(windowHeight / ADS_HEIGHT);
    } else if (windowWidth >= BROWSER_WIDTH_SMALL_THRESHOLD) {
      showAds = true;
      showAdsClass = "the-best-vertical small";
      adsLayout = "vertical small";
      direction = "vertical";
      numberOfAds = Math.floor(windowHeight / ADS_HEIGHT_SMALL);
    } else if (windowHeight >= BROWSER_HEIGHT_THRESHOLD) {
      showAds = true;
      showAdsClass = "the-best-horizontal";
      adsLayout = "horizontal";
      direction = "horizontal";
      numberOfAds = Math.floor(windowWidth / ADS_WIDTH);
    } else {
      showAds = true;
      showAdsClass = "the-best-horizontal small";
      adsLayout = "horizontal small";
      direction = "horizontal";
      numberOfAds = Math.floor(windowWidth / ADS_WIDTH_SMALL);
    }
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover
        limit={3}
        transition={Fade}
      />

      <div className={"toplevel " + showAdsClass} ref={observedDiv}>
        <ReactTooltip
          effect="solid"
          multiline={true}
          backgroundColor="var(--sl-modal)"
          textColor="var(--sl-white)"
        />

        <div className="app">
          <div className={"window-too-small " + (isTooSmall ? "active" : "")}>
            <IconBrowserResize className="icon" />
            <div className="main-text">Your browser window is too small</div>
            <div className="sub-text">
              Overlays.uno doesn't work on small displays yet. Please resize
              your browser window to at least{" "}
              {BROWSER_TOO_SMALL_WIDTH_THRESHOLD}px.
            </div>
            <div className="button-container">
              <button
                className="button"
                onClick={() =>
                  (window.document.location = "https://resources.overlays.uno")
                }
              >
                More info
              </button>
              {!isAuthenticated && (
                <button
                  className="button"
                  onClick={() => setDialogState("signup")}
                >
                  Sign up
                </button>
              )}
            </div>
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <Main
                  isAuthenticated={isAuthenticated}
                  inboxFolderHasContent={inboxFolderHasContent}
                  searchLibrary={searchLibrary}
                  searchMyOverlays={searchMyOverlays}
                  searchMyImages={searchMyImages}
                  searchInbox={searchInbox}
                  onSearchLibrary={onSearchLibrary}
                  onSearchMyOverlays={onSearchMyOverlays}
                  onSearchMyImages={onSearchMyImages}
                  onSearchInbox={onSearchInbox}
                  onShowDialog={setDialogState}
                />
              }
            >
              <Route
                index={true}
                path="home"
                element={
                  <Home
                    dialogState={dialogState}
                    onWriteActionLog={writeActionLog}
                    onShowDialog={setDialogState}
                    onFilterChanged={onLibraryFilterChanged}
                  />
                }
              />
              <Route
                path="myoverlays"
                element={<Navigate replace to="/myoverlays/all" />}
              />
              <Route
                path="myoverlays/:folder"
                element={renderAuthenticatedRoute(
                  <DashboardMyOverlays
                    search={searchMyOverlays}
                    onFilterChanged={onLibraryFilterChanged}
                  />,
                  <Navigate replace to="/home" />
                )}
              />
              <Route
                path="myimages"
                element={<Navigate replace to="/myimages/all" />}
              />
              <Route
                path="myimages/:folder"
                element={renderAuthenticatedRoute(
                  <DashboardMyImages
                    search={searchMyImages}
                    onShowDialog={setDialogState}
                  />,
                  <Navigate replace to="/home" />
                )}
              />
              <Route
                path="library/*"
                element={
                  <Library
                    search={searchLibrary}
                    isAuthenticated={isAuthenticated}
                    dialogState={dialogState}
                    filter={libraryFilter}
                    onFilterChanged={onLibraryFilterChanged}
                    onShowDialog={setDialogState}
                    onSetLibraryDetails={setLibraryDetails}
                  />
                }
              />
              <Route path="inbox/*" element={renderInboxRoute()} />
              <Route
                path="account"
                element={renderAuthenticatedRoute(
                  <Account onShowDialog={setDialogState} />,
                  <Navigate replace to="/home" />
                )}
              />
              <Route
                path="subscription"
                element={renderAuthenticatedRoute(
                  <Subscription
                    onShowDialog={setDialogState}
                    onSetStripeProductId={setStripeProductId}
                    onSetStripeProductName={setStripeProductName}
                  />,
                  <Navigate replace to="/pricing" />
                )}
              />
              <Route
                path="pricing"
                element={renderAuthenticatedRoute(
                  <Navigate replace to="/subscription" />,
                  <Subscription
                    isPriceOnly={true}
                    onShowDialog={setDialogState}
                  />
                )}
              />
              <Route path="login" element={<div></div>} />
              <Route path="signup" element={<div></div>} />
              <Route path="feedback" element={<div></div>} />
              <Route path="404" element={<Error code={404} />} />
              <Route path="500" element={<Error code={500} />} />
              <Route
                path="/resetpassword/:resetToken"
                element={
                  <ResetPasswordHelper
                    onSetPasswordResetToken={setPasswordResetToken}
                  />
                }
              />
              <Route path="*" element={<Navigate replace to="/home" />} />
            </Route>
          </Routes>
          <PageFooter onShowDialog={setDialogState} />
          <Banner />
          {renderDialogs()}
        </div>
        {showAds && (
          <div className={showAdsClass}>
            <Ads
              layout={adsLayout}
              direction={direction}
              numberOfAds={numberOfAds}
              onShowDialog={setDialogState}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default App;
