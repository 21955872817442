import React, { useState, useEffect, useContext, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Context } from "../stores/store";
import Util from "../utilities/Util";
import Config from "../stores/Config";
import mixpanel from "../utilities/MixPanel";
import moengage from "../utilities/MoEngage";

import { IconSpinner, IconClose } from "../utilities/SvgIcon";

const StripeCheckout = ({ productId, productName, onClose }) => {
  const [ContextState, ContextDispatch] = useContext(Context);

  const [stripeSessionId, setStripeSessionId] = useState(undefined);
  const [stripeClientSecret, setStripeClientSecret] = useState(undefined);
  const [waitForStripe, setWaitForStripe] = useState(false);

  const stripePromise = useRef(undefined);

  // connect to the stripe library
  useEffect(() => {
    stripePromise.current = loadStripe(Config.checkoutId);
  }, []);

  // fetch the checkout session from the server
  useEffect(() => {
    if (!productId) {
      return;
    }

    const controller = new AbortController();
    fetch(Config.paymentAPI + "uno-checkout-session", {
      signal: controller.signal,
      method: "POST",
      headers: Util.getFetchHeaders(ContextState),
      body: JSON.stringify({
        priceId: productId,
        returnUrl: "https://app.overlays.uno",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setStripeSessionId(data.sessionId);
        setStripeClientSecret(data.clientSecret);
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          Util.addNotification(
            "Failed to initiate checkout procedure",
            "error"
          );
          console.error("Error:", error);
        }
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  // start to poll the checkout status from the server
  useEffect(() => {
    if (!stripeSessionId) {
      return;
    }

    const intervalHandler = setInterval(() => {
      fetch(
        Config.paymentAPI + `uno-session-status?session_id=${stripeSessionId}`,
        {
          method: "GET",
          headers: Util.getFetchHeaders(ContextState),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "open") {
            return;
          }

          if (data.status === "complete" && data.payment_status === "paid") {
            // we are done, stop polling
            clearInterval(intervalHandler);

            // set the flag to wait for stripe to finish
            setWaitForStripe(true);

            // wait 5 seconds for stripe, then close the dialog
            setTimeout(() => {
              mixpanel.track("Checkout successful", {
                product: productName,
              });
              moengage.track("Membership Purchase Completed", {
                plan: productName,
              });

              // send the purchase event to google analytics
              if (window.dataLayer) {
                window.dataLayer.push({ event: "purchase" });
              }

              Util.addNotification("Checkout successful", "success");
              ContextDispatch({
                type: "FORCE_USER_RELOAD",
              });
              onClose();
            }, 5000);
          } else {
            mixpanel.track("Checkout failed", {
              product: productName,
            });

            Util.addNotification("Checkout failed", "error");
            onClose();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, 2000);
    return () => clearInterval(intervalHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeSessionId]);

  // this opens the checkout form
  const renderCheckoutForm = () => {
    if (waitForStripe) {
      return (
        <div className="message-busy">
          Finalizing the checkout process
          <IconSpinner className="icon" />
        </div>
      );
    }

    if (!stripeClientSecret) {
      return (
        <div className="message-busy">
          <IconSpinner className="icon" />
        </div>
      );
    }

    const options = {
      clientSecret: stripeClientSecret,
    };
    return (
      <>
        <div className="stripe-checkout">
          <EmbeddedCheckoutProvider
            stripe={stripePromise.current}
            options={options}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      </>
    );
  };

  return (
    <div className="content full-size">
      <div className="stripecheckout-header">
        <div className="title">Subscribe to {productName}</div>
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>

      {renderCheckoutForm()}
    </div>
  );
};

export default StripeCheckout;
