import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";
import mixpanel from "../utilities/MixPanel"
import moengage from "../utilities/MoEngage"

import { IconSpinner, IconClose } from "../utilities/SvgIcon";

const SendFeedback = ({ onShowDialog, onClose }) => {
  const [ContextState, ContextDispatch] = useContext(Context);
  const [message, setMessage] = useState("");
  const [busy, setBusy] = useState(false);

  const navigate = useNavigate();

  const onSendFeedback = (event) => {
    setBusy(true);

    fetch(Config.singularUrl + "/apiv2/users/feedback", {
      method: "POST",
      body: JSON.stringify({
        email: ContextState.userInfo
          ? ContextState.userInfo.email
          : "Anonymous",
        message: message.replace(/(?:\r\n|\r|\n)/g, "<br>"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setBusy(false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        Util.addNotification("Your message has been sent");
        onClose();
      })
      .catch((error) => {
        setBusy(false);
      });

    mixpanel.track("Send Feedback");
    moengage.track("Support Feedback Ticket Completed");
  };

  return (
    <div className="content">
      <div className="sendfeedback-header">
        <div className="title">Send Feedback</div>
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>
      <div className="message">
        Your feedback helps steer the ship. Whether you have suggestions for new
        overlays or other uno improvements, we're listening.
      </div>
      <div className="form">
        <div className="label-input">
          <div className="label">Your message</div>
          <textarea
            className="input textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            autoFocus
            rows={5}
          />
        </div>
      </div>
      {!ContextState.userInfo && (
        <div className="message">
          Please log in{" "}
          <span className="message-link" onClick={() => onShowDialog("login")}>
            here
          </span>
          , otherwise your feedback will be submitted
          <br />
          anonymously and
          <span className="message-highlight">
            {" "}
            we won
            {"'"}t be able to respond.
          </span>
          <br />
          <br />
          <span>
            Join the uno community on our{" "}
            <span
              className="message-link"
              onClick={() => Util.openUrl("https://discord.gg/g6rJYsXua9")}
            >
              Discord server
            </span>
          </span>
        </div>
      )}
      <button
        className="button"
        onClick={onSendFeedback}
        disabled={busy || !message.trim()}
      >
        {!busy && !ContextState.userInfo && "Send Anonymously"}
        {!busy && ContextState.userInfo && "Send"}
        {busy && <IconSpinner />}
      </button>
    </div>
  );
};

export default SendFeedback;
