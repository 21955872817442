import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";
import EmailInput from "./EmailInput";
import PasswordInput from "./PasswordInput";
import GoogleButton from "./GoogleButton";

import { IconSpinner, IconClose } from "../utilities/SvgIcon";

const Login = ({ initialEmail, initialMode, onShowDialog, onClose }) => {
  const [mode, setMode] = useState(initialMode || "login");
  const [email, setEmail] = useState(initialEmail);
  const [emailOK, setEmailOK] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordOK, setPasswordOK] = useState(false);
  const [busy, setBusy] = useState(false);
  const [showSignUpMessage, setShowSignupMessage] = useState(false);

  const [, ContextDispatch] = useContext(Context);

  const navigate = useNavigate();

  const onButtonClick = () => {
    if (mode === "login") {
      handleLogin();
    } else {
      handleSignup();
    }
  };

  const handleSignup = () => {
    setBusy(true);

    fetch(Config.singularUrl + "/apiv2/users/registerjwt?brand=uno", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setBusy(false);
        Util.handleFetchError(result, ContextDispatch, navigate, 15000);
        Util.addNotification(
          <span>
            An email has been sent to <b>{email}</b>
          </span>,
          "success",
          10000
        );
        setMode("login");
      })
      .catch((error) => {
        setBusy(false);
      });
  };

  const handleLogin = () => {
    setBusy(true);

    fetch(Config.singularUrl + "/apiv2/users/logintoken", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBusy(false);
        if (data && data.success) {
          ContextDispatch({
            type: "SET_UNO_ACCESS_TOKEN",
            payload: data.accessToken,
          });
          ContextDispatch({
            type: "SET_UNO_REFRESH_TOKEN",
            payload: data.refreshToken,
          });
          onClose();
          navigate("/myoverlays");
        } else {
          if (data.error) {
            Util.addNotification(data.error.message, "error");
            setShowSignupMessage(true);
          } else {
            Util.addNotification(
              "Error while processing authentication",
              "error"
            );
          }
        }
      })
      .catch((error) => {
        Util.addNotification(
          "Server connection error, please try again layer",
          "error"
        );
        setBusy(false);
        setShowSignupMessage(true);
      });
  };

  const renderSubTitle = () => {
    if (mode === "login") {
      if (showSignUpMessage) {
        return (
          <div className="sub-title">
            If you tried to sign up please click{" "}
            <span className="mode-toggle" onClick={() => setMode("signup")}>
              here.
            </span>
          </div>
        );
      }
      return <div className="sub-title">&nbsp;</div>;
    } else {
      return <div className="sub-title">Free - no credit card required</div>;
    }
  };
  return (
    <div className="content">
      <div
        className={"login-header" + (mode === "login" ? " login" : " signup")}
      >
        <img className="logo" src={Config.unoLogoMedium} alt="" />
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>
      <div className="control-scroll">
        <div className="title">
          {mode === "login" ? "Log in" : "Sign up"}
          {renderSubTitle()}
        </div>
        <div className="form">
          <EmailInput
            value={email}
            onChange={(value) => setEmail(value)}
            onOk={(value) => setEmailOK(value)}
          />
          <div className="password-group">
            <PasswordInput
              onChange={(value) => setPassword(value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && passwordOK && emailOK && !busy) {
                  onButtonClick();
                }
              }}
              onPasswordOk={(v) => setPasswordOK(v)}
              minLength={mode === "login" ? 0 : 5}
            />
            {mode === "login" && (
              <div
                className="link"
                onClick={() => {
                  onShowDialog("forgotpassword");
                }}
              >
                Forgot password?
              </div>
            )}
            {mode === "signup" && <div style={{ height: "32px" }}></div>}
          </div>

          <div className="login-buttons">
            <button
              className="button-brand login-button"
              onClick={onButtonClick}
              disabled={!passwordOK || !emailOK || busy}
            >
              {!busy && <span>{mode === "login" ? "Log in" : "Sign up"}</span>}
              {busy && <IconSpinner />}
            </button>
            <GoogleButton mode={mode} disabled={busy} />
          </div>
        </div>
        {mode === "login" && (
          <div className="sign-up">
            Not a member?
            <div className="mode-toggle" onClick={() => setMode("signup")}>
              Sign up now!
            </div>
          </div>
        )}
        {mode === "signup" && (
          <div className="sign-up">
            Already a member?
            <div className="mode-toggle" onClick={() => setMode("login")}>
              Log in here!
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
