import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Context } from "../stores/store";

import { IconSpinner } from "../utilities/SvgIcon";

import LibraryFilter from "./LibraryFilter";
import LibraryList from "./LibraryList";
import Util from "../utilities/Util";

const Library = ({
  search,
  isAuthenticated,
  filter,
  dialogState,
  onFilterChanged,
  onShowDialog,
  onSetLibraryDetails,
}) => {
  const [ContextState] = useContext(Context);
  const [initialOverlayId, setInitialOverlayId] = useState(-1);

  const [allContentLoaded, setAllContentLoaded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // we only search for uppercase and without spaces
  const cleanSearch = search.trim().toUpperCase();

  // filter elements from the list
  let filteredList = ContextState.libraryContent.filter((item) => {
    if (cleanSearch) {
      // check the content names
      let contentMatchFound = false;
      let content = item.content;
      if (content && content.content && content.content.length) {
        contentMatchFound = content.content.find((c) => {
          return c.name.toUpperCase().indexOf(cleanSearch) !== -1;
        });
      }

      // prepare data to check the tags
      // let itemTags = "";
      // if (item.tags && item.tags.length) {
      //   itemTags = item.tags.join(" ").toUpperCase();
      // }

      // check name, id, tags and description without the HTML tags
      // let cleanDescription = item.description.replace(/<\/?[^>]+(>|$)/g, "");
      if (
        !contentMatchFound &&
        item.name.toUpperCase().indexOf(cleanSearch) === -1 &&
        item.tag_type.toUpperCase().indexOf(cleanSearch) === -1 &&
        item.tag_function.toUpperCase().indexOf(cleanSearch) === -1 &&
        item.tag_category.toUpperCase().indexOf(cleanSearch) === -1 &&
        item.tag_theme.toUpperCase().indexOf(cleanSearch) === -1 &&
        item.tag_datasource.toUpperCase().indexOf(cleanSearch) === -1 &&
        String(item.id) !== cleanSearch // &&
        //        itemTags.indexOf(cleanSearch) === -1 // &&
        //        cleanDescription.toUpperCase().indexOf(cleanSearch) === -1
      ) {
        return null;
      }
    }

    let accept = true;
    for (const [property, tags] of Object.entries(filter)) {
      if (tags.length) {
        // a special case for the layout filter
        if (property === "tag_layout") {
          const variants = Util.getLibraryItemDisplayVariants(item);

          // check all the tags against the variants
          for (let i = 0; i < tags.length; i++) {
            const numberOfVariants = Util.getNumberOfVariantsForLayout(variants, tags[i]);
            if (!numberOfVariants) {
              accept = false;
              break;
            }
          }
        } else {
          if (!item[property] || tags.indexOf(item[property]) === -1) {
            accept = false;
          }
        }
      }
    }
    if (!accept) {
      return null;
    }
    return item;
  });

  // sort the elements
  switch (ContextState.librarySort) {
    case "popular":
      filteredList.sort((a, b) => {
        let rankA = ContextState.libraryPopular.findIndex(
          (item) => item.id === a.id
        );
        let rankB = ContextState.libraryPopular.findIndex(
          (item) => item.id === b.id
        );
        if (rankA === -1) {
          rankA = 9999999;
        }
        if (rankB === -1) {
          rankB = 9999999;
        }
        if (rankA < rankB) {
          return -1;
        }
        if (rankA > rankB) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
      break;
    case "trending":
      filteredList.sort((a, b) => {
        let rankA = ContextState.libraryTrending.findIndex(
          (item) => item.id === a.id
        );
        let rankB = ContextState.libraryTrending.findIndex(
          (item) => item.id === b.id
        );
        if (rankA === -1) {
          rankA = 9999999;
        }
        if (rankB === -1) {
          rankB = 9999999;
        }
        if (rankA < rankB) {
          return -1;
        }
        if (rankA > rankB) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
      break;
    case "changed91":
      filteredList.sort((a, b) => {
        // get how many days are left for the new state
        let leftA = Util.getLibraryNewReleaseDaysLeft(a);
        let leftB = Util.getLibraryNewReleaseDaysLeft(b);

        // if both have days left then sort by the days left
        if (leftA && leftB) {
          if (leftA > leftB) {
            return -1;
          }
          if (leftA < leftB) {
            return 1;
          }
        } else if (leftA) {
          return -1;
        } else if (leftB) {
          return 1;
        }

        if (a.updated_at < b.updated_at) {
          return 1;
        }
        if (a.updated_at > b.updated_at) {
          return -1;
        }
        return a.name.localeCompare(b.name);
      });
      break;
    case "alphaAZ":
    default:
      filteredList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      break;
  }

  // check if all content is loaded
  useEffect(() => {
    if (
      ContextState.libraryContentLoaded &&
      ContextState.libraryPopularLoaded &&
      ContextState.libraryTrendingLoaded
    ) {
      setAllContentLoaded(true);
    }
  }, [
    ContextState.libraryContentLoaded,
    ContextState.libraryPopularLoaded,
    ContextState.libraryTrendingLoaded,
  ]);

  // when the path name changes we check
  useEffect(() => {
    if (!allContentLoaded) {
      return;
    }

    let itemId;
    let path = location.pathname;
    if (path.indexOf("/library/") === 0) {
      itemId = parseInt(path.substring("/library/".length, path.indexOf("-")));
    }
    // this is the id of the market place item
    if (itemId) {
      // the overlays are not loaded yet so remember the overlay ID and open the details later
      if (!ContextState.libraryContent.length) {
        setInitialOverlayId(itemId);
        return;
      }

      // get the item itself
      let item = ContextState.libraryContent.find((item) => {
        return itemId === item.id;
      });

      if (item) {
        onShowDialog("librarydetails");
        onSelectItem(item);
      } else {
        navigate("/library", { replace: true });
      }
      setInitialOverlayId(0);
    } else {
      navigate("/library", { replace: true });
      setInitialOverlayId(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, allContentLoaded]);

  // if we are at library and the dialog is not open then navigate back to the library
  // this is a hack! I should do that in App.jsx where I close the dialog, but
  // at that point I do not have a router for the navigate()
  useEffect(() => {
    if (!allContentLoaded) {
      return;
    }
    if (
      location.pathname !== "/library" &&
      dialogState === "" &&
      !initialOverlayId
    ) {
      navigate("/library");
      setInitialOverlayId(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dialogState, initialOverlayId, allContentLoaded]);

  useEffect(() => {
    // we should open an overlay detail from the start using the URL
    if (initialOverlayId && ContextState.libraryContent.length) {
      // find it
      let item = ContextState.libraryContent.find((item) => {
        return initialOverlayId === item.id;
      });

      // if it exists open the detail view
      if (item) {
        onShowDialog("librarydetails");
        onSelectItem(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.libraryContent]);

  const onSelectItem = (item) => {
    let libraryDetail = {
      item: item,
      previousUrl: undefined,
      nextUrl: undefined,
    };

    if (item) {
      let index = filteredList.findIndex((i) => {
        return item.id === i.id;
      });
      if (index > 0) {
        libraryDetail.previousUrl =
          "/library/" + Util.getLibraryItemPath(filteredList[index - 1]);
      }
      if (index < filteredList.length - 1) {
        libraryDetail.nextUrl =
          "/library/" + Util.getLibraryItemPath(filteredList[index + 1]);
      }
    }

    onSetLibraryDetails(libraryDetail);
    onShowDialog("librarydetails");

    if (item) {
      const newUrl = "/library/" + Util.getLibraryItemPath(item);
      if (location.pathname !== newUrl) {
        document.title = "uno - Live overlays for streaming - " + item.name;
        navigate(newUrl);
      }
    } else {
      document.title = "uno - Live overlays for streaming";
      navigate("/library", { replace: true });
    }
  };

  // show busy bubbles
  if (!allContentLoaded) {
    return (
      <div className="library-browser">
        <div className="full-screen-panel">
          <IconSpinner />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="library-browser">
        <div className="columns">
          <div className="left-column">
            <LibraryFilter
              items={ContextState.libraryContent}
              filter={filter}
              onChanged={(property, value) =>
                onFilterChanged("set", property, value)
              }
              onSetAll={(value) => onFilterChanged("setAll", "", value)}
              onReset={() => onFilterChanged("clear")}
            />
          </div>
          <div className="right-column">
            <LibraryList
              items={filteredList}
              onFilterChanged={onFilterChanged}
              onSelectItem={onSelectItem}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Library;
