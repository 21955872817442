import { useContext, useState, forwardRef, useEffect } from "react";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";
import UploadImageItem from "./UploadImageItem";

import Uploady, {
  useRequestPreSend,
  useItemFinishListener,
  useBatchAddListener,
  useUploady,
} from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";
import { asUploadButton } from "@rpldy/upload-button";
import { withPasteUpload } from "@rpldy/upload-paste";

import { IconClose } from "../utilities/SvgIcon";

import mixpanel from '../utilities/MixPanel';

const UploadImage = ({ isAuthenticated, onAllowClose, onClose }) => {
  const [ContextState, ContextDispatch] = useContext(Context);

  const [activeUploads, setActiveUploads] = useState([]);

  // find out of there are any active uploads
  const getUploadingCount = () => {
    return activeUploads.filter((item) => {
      if (item.status === "error" || item.status === "done") {
        return false;
      }
      return true;
    }).length;
  };

  // check if there is an active upload and prevent the user from closing the window
  useEffect(() => {
    if (getUploadingCount() > 0) {
      onAllowClose(false);
    } else {
      onAllowClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUploads]);

  // this is our custom upload button
  const DivUploadButton = asUploadButton(
    forwardRef((props, ref) => (
      <div {...props} ref={ref}>
        <button className="button">Choose File</button>
      </div>
    ))
  );

  // the past and upload drop zone
  const PasteUploadDropZone = withPasteUpload(UploadDropZone);

  const getActiveUpload = (id) => {
    return activeUploads.find((item) => item.id === id);
  };

  const addActiveUpload = (data) => {
    setActiveUploads((prev) => [...prev, data]);
  };

  const modifyActiveUpload = (id, data) => {
    setActiveUploads((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, ...data };
        }
        return item;
      });
    });
  };

  const onRemoveItem = (id) => {
    setActiveUploads((prev) => {
      return prev.filter((item) => item.id !== id);
    });
  };

  const createPreviewImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      var dataUrl = URL.createObjectURL(file);
      img.src = dataUrl;

      img.onload = () => {
        resolve({
          dataUrl: dataUrl,
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    });
  };

  const CheckIfFileIsValid = (file) => {
    // check if file type is part of my list
    if (
      [
        "image/jpeg",
        "image/png",
        "image/apng",
        "image/gif",
        "image/webp",
      ].indexOf(file.type) === -1
    ) {
      return "File type is not supported";
    }

    // check the file size
    if (file.size > 10 * 1024 * 1024) {
      return "Maximum file size is 10MB";
    }
    return null;
  };

  const UploadFiles = () => {
    const { upload } = useUploady();

    // do we have images to upload?
    if (ContextState.imageDropped.length) {
      // add them to the upload list
      upload(ContextState.imageDropped);

      // reset the imageDropped
      ContextDispatch({
        type: "SET_IMAGE_DROPPED",
        payload: [],
      });
    }
  };

  const ReceiveSelectedFiles = () => {
    useBatchAddListener((batch) => {
      if (!batch || !batch.items || batch.items.length === 0) {
        return false;
      }

      // loop through the items array
      batch.items.forEach((item) => {
        const { file, id } = item;

        // if the file is not valid then add it to the list as error
        const result = CheckIfFileIsValid(file);
        if (result) {
          addActiveUpload({
            status: "error",
            id: id,
            file: file,
            completed: 0,
            message: result,
          });
          return;
        }

        // add the file to the active uploads
        addActiveUpload({
          status: "init",
          id: id,
          file: file,
          completed: 0,
          message: "",
        });
      });
    });

    return null;
  };

  const SignedUpload = () => {
    useRequestPreSend(async ({ items, options }) => {
      const files = items.length > 0 ? items[0] : {};

      let { file, id } = files;
      if (!file) {
        return false;
      }

      // reject the file if it is not valid
      if (CheckIfFileIsValid(file)) {
        return false;
      }

      // get the size of the image
      let preview = await createPreviewImage(file);
      if (!preview) {
        modifyActiveUpload(id, {
          status: "error",
          message: "Unable to read image dimensions",
        });
        return false;
      }

      // change the status to upload and add the preview icon
      modifyActiveUpload(id, {
        status: "upload",
        mesage: "",
        preview: preview,
      });

      // now get a signed url for S3 from Singular so we can upload the file
      const result = await fetch(
        Config.singularUrl + "/apiv2/images/signupload",
        {
          method: "POST",
          body: JSON.stringify({
            fileName: file.name,
            originalFileName: file.name,
            ext: "",
            fileType: file.type,
            width: preview.width,
            height: preview.height,
          }),
          headers: Util.getFetchHeaders(ContextState),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.error) {
            modifyActiveUpload(id, {
              status: "error",
              message: "Authentication failed",
            });
            return false;
          }
          return result;
        })
        .catch((error) => {
          modifyActiveUpload(id, {
            status: "error",
            message: "Authentication failed",
          });
          return false;
        });

      if (!result) {
        return false;
      } else {
        // update the active uploads with the signed url
        modifyActiveUpload(id, {
          status: "upload",
          message: "",
          preview: preview,
          url: result.signedRequest,
        });
      }

      return {
        options: {
          sendWithFormData: false,
          destination: {
            url: result.signedRequest,
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
          },
        },
      };
    });

    return (
      <>
        <PasteUploadDropZone
          className="drop-zone"
          onDragOverClassName="drag-over"
        >
          <span>Drop files here to upload</span>
        </PasteUploadDropZone>
        <DivUploadButton />
      </>
    );
  };

  const ReceiveFinishedFiles = () => {
    useItemFinishListener((file) => {
      const item = getActiveUpload(file.id);

      // the upload was successful. Now create the image
      if (item && item.url) {
        let folder = ContextState.currentImageDownloadFolder;
        if (!folder) {
          folder = ContextState.dashboardRootFolderImages;
        }

        modifyActiveUpload(file.id, {
          status: "creating",
          message: "",
        });

        // remove the extension from the file name
        const name = String(item.file.name).replace(/\.[^/.]+$/, "");

        // remove the query string from the url
        const url = item.url.split("?")[0];

        // parse the url to get the baseUrl and fileName
        fetch(Config.singularUrl + "/apiv2/images", {
          method: "POST",
          body: JSON.stringify({
            name: name,
            type: item.file.type,
            fileSize: item.file.size,
            width: item.preview.width,
            height: item.preview.height,
            url: url,
            folder: folder,
          }),
          headers: Util.getFetchHeaders(ContextState),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.error) {
              modifyActiveUpload(file.id, {
                status: "error",
                message: "Failed to create image",
              });
              return false;
            }
            if (getUploadingCount() <= 1) {
              ContextDispatch({
                type: "DASHBOARD_IMAGES_FORCE_RELOAD",
              });
            }
            modifyActiveUpload(file.id, {
              status: "done",
              message: "",
            });

            // tell analytics that an image was uploaded
            let payload = {
              event: "Uno image upload",
              data: {},
            };
            if (isAuthenticated) {
              //payload.data.distinct_id = ContextState.userInfo.accountId;
              payload.data.user = ContextState.userInfo.email;
            } else {
              //payload.data.distinct_id = "Try me";
              payload.data.user = "Try me";
            }

            mixpanel.track(payload.event, payload.data);

            return result;
          })
          .catch((error) => {
            modifyActiveUpload(file.id, {
              status: "error",
              message: "Failed to create image",
            });
            return false;
          });
      }
    });
    return null;
  };

  const uploadingTitle = () => {
    if (activeUploads.length === 0) {
      return "Uploading";
    }

    const active = activeUploads.filter((item) => {
      if (item.status === "error" || item.status === "init") {
        return false;
      }
      return true;
    });

    const valid = activeUploads.filter((item) => {
      if (item.status === "error") {
        return false;
      }
      return true;
    });

    return "Uploading " + active.length + " of " + valid.length;
  };

  return (
    <div className="uploadimage">
      <div className="uploadimage-header">
        <div className="title">Upload Image</div>
        <span
          className="close"
          onClick={() => {
            onClose();
          }}
        >
          <IconClose />
        </span>
      </div>
      <Uploady destination={{}} debug={false}>
        <UploadFiles />
        <ReceiveSelectedFiles />
        <ReceiveFinishedFiles />
        <div className="content">
          <div className="upload-area">
            <SignedUpload />
          </div>

          <div className="border"></div>

          <div className="processing-area">
            <div className="header-bar">
              <div className="title">{uploadingTitle()}</div>
              {/* 
              <div className="actions"><IconCleanup /></div>
             */}
            </div>

            <div className="processing-list">
              {activeUploads.length === 0 && (
                <span className="processing-list-empty">
                  Uploading images will be shown here
                </span>
              )}
              {activeUploads.map((item, index) => (
                <UploadImageItem
                  key={index}
                  item={item}
                  onRemoveItem={onRemoveItem}
                />
              ))}
            </div>
          </div>
        </div>
      </Uploady>
    </div>
  );
};

export default UploadImage;
